<template>
	<main>
		<HeaderTab 
			:title="$t('client_area.contract_options')" 
            :swiper_tabs="swiper_tabs" 
		/>

        <div id="content">
			<router-view></router-view>
		</div>
	</main>
</template>


<script type="text/javascript">
	export default {
		name: "ContractOptionsClientArea",
		computed: {
			swiper_tabs() {
				return [{
						label: 'menu.client_area.settings.auto_invoice',
						href : 'autoinvoiceCaContract',
						active: true
					}, {
						label: 'menu.client_area.settings.cgu_cgv',
						href : 'cguCgvCaConditions',
                        active: false
					}
				]
			}
		},
		components: {
			HeaderTab: () => import('@/components/HeaderTab')
		}
	}
</script>
